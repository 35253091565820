
































































































import { Button, Cell, CellGroup, Dialog, Field, Icon, Picker, Popup, Toast } from "vant";
import Vue from "vue";
import Component from "vue-class-component";
import LocationPicker from "@/components/picker/location-picker.vue";
import { Prop, Watch } from "vue-property-decorator";

interface Location {
  latitude: number;
  longitude: number;
  detail: {
    address: string;
    area: string;
    city: string;
    province: string;
  };
}

@Component({
  components: {
    [Field.name]: Field,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    LocationPicker
  }
})
export default class CabinetBind extends Vue {
  @Prop({ default: 0 }) cabinetId!: number | string;
  id = 0;
  box2Id = null as number | null;
  qrCode = "";
  count = 0;
  cabinetType = -1;
  mark = "";

  unitTime = "" as number | string;
  connectVersion = 0;

  roomQrCodeList = [];
  latitude = 0.0;
  longitude = 0.0;
  address = "";
  area = "";
  city = "";
  province = "";

  cabinetTypePicker = {
    show: false,
    msg: "",
    list: [
      { type: 0, msg: "充电设备" },
      { type: 1, msg: "换电柜(1代)" },
      { type: 2, msg: "换电柜(2代)" }
    ]
  };
  showLocationPicker = false;
  box2SearchDialog = {
    show: false,
    roomId: ""
  };
  countDialog = {
    show: false,
    count: 0
  };
  unitTimeDialog = {
    show: false,
    unitTime: "" as string | number,
    act: ""
  };

  // 生命周期
  created() {
    this.id = Number(this.cabinetId) || 0;
    this.$readyWx(this.$axios, ["scanQRCode"]);
    if (this.id) {
      this.getData();
    }
  }

  // 逻辑
  save() {
    const postData = {
      box2Id: this.box2Id,
      qrCode: this.qrCode,
      cabinetType: this.cabinetType,
      count: this.count,
      mark: this.mark || "",
      latitude: this.latitude,
      longitude: this.longitude,
      address: this.address,
      area: this.area,
      city: this.city,
      province: this.province,
      unitTime: this.unitTime,
      roomQrCodes: this.roomQrCodeList.join(",")
    };
    this.$axios.post("/api/manage/cabinet/bind2", postData).then(res => {
      if (res.data.status == 2) {
        this.unitTimeDialog.show = true;
        this.unitTimeDialog.unitTime = this.unitTime;
        this.unitTimeDialog.act = "save";
        return;
      } else {
        Toast.success("保存成功");
        this.id = res.data.data.id;
        this.getData();
      }
    });
  }
  getData() {
    this.$axios.post("/api/manage/cabinet/getBindInfo", { cabinetId: this.id }).then(res => {
      const data = res.data.data;
      this.box2Id = data.box2Id;
      this.qrCode = data.qrCode;
      this.cabinetType = data.cabinetType;
      this.mark = data.mark;
      this.count = data.count;
      this.connectVersion = data.connectVersion;
      this.unitTime = data.unitTime;
      this.latitude = data.latitude;
      this.longitude = data.longitude;
      this.area = data.area;
      this.city = data.city;
      this.province = data.province;
      this.address = data.address;
      this.roomQrCodeList = data.roomQrCodeList;

      const cabinetType = this.cabinetTypePicker.list.find(item => {
        return item.type == data.cabinetType;
      });
      this.cabinetTypePicker.msg = cabinetType ? cabinetType.msg : "";
    });
  }
  updateBox2Id(isForce: boolean) {
    const requestData = { cabinetId: this.id, box2Id: this.box2Id, isForce: isForce };
    this.$axios.post("/api/manage/cabinet/updateBox2Id", requestData).then(res => {
      if (res.data.status == 2) {
        Dialog.confirm({
          message: res.data.data
        }).then(() => {
          this.updateBox2Id(true);
        });
      } else {
        Toast.success("修改成功");
      }
    });
  }
  scanQrCode() {
    this.$scanCode(c => {
      this.qrCode = c.split("?c=")[1];
      this.updateQrCode(false);
    });
  }
  updateQrCode(isForce: boolean) {
    const requestData = { cabinetId: this.id, qrCode: this.qrCode, isForce: isForce };
    this.$axios.post("/api/manage/cabinet/updateQrCode", requestData).then(res => {
      if (res.data.status == 2) {
        Dialog.confirm({
          message: res.data.data
        }).then(() => {
          this.updateQrCode(true);
        });
      } else {
        Toast.success("修改成功");
      }
    });
  }
  updateCabinetType() {
    const requestData = { cabinetId: this.id, cabinetType: this.cabinetType };
    this.$axios.post("/api/manage/cabinet/updateCabinetType", requestData).then(() => {
      Toast.success("修改成功");
    });
  }
  updateMark() {
    const requestData = { cabinetId: this.id, mark: this.mark };
    this.$axios.post("/api/manage/cabinet/updateMark", requestData).then(() => {
      Toast.success("修改成功");
    });
  }
  updateUnitTime() {
    const requestData = { cabinetId: this.id, unitTime: this.unitTime };
    this.$axios.post("/api/manage/cabinet/updateUnitTime", requestData).then(() => {
      Toast.success("修改成功");
    });
  }
  updateCount() {
    const requestData = { cabinetId: this.id, count: this.count };
    this.$axios.post("/api/manage/cabinet/cabinetRoomInit", requestData).then(() => {
      Toast.success("修改成功");
      this.getData();
    });
  }
  updateLocation() {
    const requestData = {
      cabinetId: this.id,
      latitude: this.latitude,
      longitude: this.longitude,
      address: this.address,
      area: this.area,
      city: this.city,
      province: this.province
    };
    this.$axios.post("/api/manage/cabinet/updateLocation", requestData).then(() => {
      Toast.success("修改成功");
    });
  }
  scanRoomQrCode(no: number) {
    this.$scanCode(c => {
      this.$set(this.roomQrCodeList, no - 1, c.split("?c=")[1]);
      this.updateRoomQrCode(no, false);
    });
  }
  updateRoomQrCode(no: number, isForce: boolean) {
    const requestData = {
      cabinetId: this.id,
      no: no,
      qrCode: this.roomQrCodeList[no - 1],
      isForce: isForce
    };
    this.$axios.post("/api/manage/cabinet/updateRoomQrCode", requestData).then(res => {
      if (res.data.status == 2) {
        Dialog.confirm({
          message: res.data.data
        }).then(() => {
          this.updateRoomQrCode(no, true);
        });
      } else {
        Toast.success("修改成功");
      }
    });
  }
  searchBox2IdByRoomId() {
    this.$axios.post("/api/manage/cabinet/getBox2IdByRoom", { roomId: this.box2SearchDialog.roomId }).then(res => {
      const data = res.data.data;
      this.box2Id = data;
      Toast.success("获取成功");
    });
  }
  @Watch("box2Id")
  autoFillByBox2Id(box2Id: number) {
    if (box2Id >= Math.pow(10, 9)) {
      this.$axios.post("/api/manage/cabinet/getInfoByBox2IdForBind", { box2Id: box2Id }).then(res => {
        const data = res.data.data;
        this.onPickCabinetType(
          this.cabinetTypePicker.list.find(item => {
            return item.type == data.cabinetType;
          }) || { type: 0, msg: "" }
        );
        this.count = data.count;
        this.connectVersion = data.connectVersion;
      });
    }
  }

  // UI
  get isAdd() {
    return this.id == 0;
  }
  get isSaveReady() {
    return this.box2Id && this.qrCode && this.cabinetType >= 0 && this.count > 0;
  }
  close() {
    if (this.$router.currentRoute.name == "device-cabinet-bind") {
      this.$router.go(-1);
    } else {
      this.$emit("close", this.id);
    }
  }
  onPickLocation(location: Location) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
    this.address = location.detail.address;
    this.area = location.detail.area;
    this.city = location.detail.city;
    this.province = location.detail.province;
  }
  onPickCabinetType(cabinetType: { msg: string; type: number }) {
    this.cabinetType = cabinetType.type;
    this.cabinetTypePicker.show = false;
    this.cabinetTypePicker.msg = cabinetType.msg;
  }
  showCountDialog() {
    this.countDialog.count = this.count;
    this.countDialog.show = true;
  }
  setCount() {
    this.countDialog.show = false;
    this.count = this.countDialog.count;
    this.roomQrCodeList.length = this.count;
    if (!this.isAdd) {
      this.updateCount();
    }
  }
  setUnitTime() {
    this.unitTimeDialog.show = false;
    this.unitTime = this.unitTimeDialog.unitTime;
    if (this.unitTimeDialog.act == "save") {
      this.save();
      this.unitTimeDialog.act = "";
    }
  }
}
